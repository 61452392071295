<template>
  <div>
    <CWrapper>
      <TheHeader />
      <CRow style="margin-top: 10rem">
        <strong style="margin-left: 4rem">Profile</strong><small></small>
        <CCol sm="1"></CCol>

        <CCol sm="6">
          <CForm class="pt-2" @submit.prevent="UpdateUser">
            <div>
              <b-alert
                v-if="errorMessage != null"
                v-model="showDismissibleAlert"
                variant="success"
                dismissible
              >
                {{ errorMessage }}
              </b-alert>
            </div>
            <div>
              <p style="white-space: preline"></p>
            </div>
            <CInput
              v-model="signupdetails.name"
              placeholder="Your Name"
              autocomplete="username"
              required
              onkeypress="return /[a-z]/i.test(event.key)"
            >
            </CInput>
            <input
              type="email"
              v-model="signupdetails.email"
              placeholder="Your Email"
              id="email"
              name="email"
              class="form-control mb-3"
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
              required
            />

            <div>
              <b-form-select
                v-model="signupdetails.location"
                :options="options"
              ></b-form-select>
            </div>
            <div class="pt-3">
              <b-form-input
                type="number"
                v-model="signupdetails.mobile"
                placeholder="Mobile Number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                minlength="10"
                required
              ></b-form-input>
              <span id="errMessage"></span>
            </div>
            <b-button class="mt-3" type="submit" variant="primary"
              >Submit</b-button
            >
          </CForm>
        </CCol>
      </CRow>
    </CWrapper>
  </div>
</template>
<script>
import TheSidebar from "../../containers/TheSidebar";
import TheHeader from "../../containers/TheHeader";
import TheFooter from "../../containers/TheFooter";
import { mapActions } from "vuex";

export default {
  name: "Forms",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      showDismissibleAlert: false,
      errorMessage: null,
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "india", text: "India" },
      ],
      horizontal: { label: "col-3", input: "col-9" },

      signupdetails: {
        id: null,
        name: null,
        email: null,
        mobile: null,
        check_terms: null,
        selected_radio: null,
        password: null,
        password_confirmation: null,
        location: null,
      },
    };
  },
  created() {
    this.getUserProfileDetail();
  },
  methods: {
    ...mapActions(["getUserUpdateList", "UpdateUserAdminDetail"]),
    getUserProfileDetail() {
      this.signupdetails.id = localStorage.getItem("user");
      this.getUserUpdateList(this.signupdetails).then((response) => {
        this.signupdetails.name = response.data.data[0].name;
        this.signupdetails.id = response.data.data[0].id;
        this.signupdetails.mobile = response.data.data[0].mobile;
        this.signupdetails.email = response.data.data[0].email;
        this.signupdetails.location = response.data.data[0].location;
        this.signupdetails.selected_radio =
          response.data.data[0].subscription_plan;
      });
    },

    UpdateUser() {
      this.UpdateUserAdminDetail(this.signupdetails)
        .then((response) => {
          this.showDismissibleAlert = true;
          this.errorMessage = "Successfully Updated";
        })
        .catch((error) => {
          this.showDismissibleAlert = true;
        });
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
  },
};
</script>
